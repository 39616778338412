import React, { useState, useEffect } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
// import Router from 'next/router'

import { getChecks, Check, getChecksById } from '../../utils/checks'
import Renderer from '../form/richEditorRenderer'
import { IconAddToCart, IconRemoveFromCart } from '../icons'
import Pagination from '../table/pagination'
import SidePanel from '../sidePanel'
import Strings from '../strings'

const More = ({ check }) => {
  const [openState, setOpenState] = useState(false)
  const { id, title, norm, description, valid } = check

  return (
    <>
      <button className="c=prim-alt" onClick={() => setOpenState(true)}>
        {Strings.de.components.cms.info}
      </button>
      <SidePanel openState={openState} closeCallback={() => setOpenState(false)}>
        <h1 className="mb=2">{title}</h1>
        <p className="mb=3">{norm}</p>
        <div className="mb=3">
          <Renderer raw={description} />
        </div>
      </SidePanel>
    </>
  )
}

interface Props {
  // hasEditOptions: boolean
  checks: Check[]
  page: number
  perPage: number
  totalRecords: number
  category: string[]
  token: string
}

const CheckSellTable: React.FunctionComponent<Props> = props => {
  const token = props.token
  const { category } = props
  const [checks, setChecks] = useState(props.checks)
  const [page, setPage] = useState(props.page)
  const [perPage, setPerPage] = useState(props.perPage)
  const [totalRecords, setTotalRecords] = useState(props.totalRecords)
  const [searchValue, setSearchValue] = useState('')
  const [cart, setCart] = useState<Check[]>([])
  const [refresh, setRefresh] = useState(false)
  const [refreshCart, setRefreshCart] = useState(false)

  const event = new Event('cartChange')

  const doRefresh = () => setRefresh(!refresh)
  const doRefreshCart = () => setRefreshCart(!refreshCart)

  const setResponseStates = ({ perPage, page, data: checks, totalRecords }) => {
    setPerPage(perPage)
    setPage(page)
    setChecks(checks)
    setTotalRecords(totalRecords)
  }

  useEffect(() => {
    getChecks(token, page, perPage, { searchValue, categoryIds: category.join(), sortBy: 'type', sortDirection: 'asc' }).then(setResponseStates)
  }, [page, perPage, totalRecords, refresh, category])

  useEffect(() => {
    const ids = JSON.parse(localStorage.getItem('cart') || JSON.stringify([]))

    getChecksById(ids).then(checks => {
      setCart(checks)
    })
  }, [refreshCart])

  const addToCart = (id: string) => {
    localStorage.setItem('cart', JSON.stringify([...cart.map(({ id }) => id), id]))
    document.dispatchEvent(event)
    doRefreshCart()
  }

  const removeFromCart = (tmpId: string) => {
    localStorage.setItem('cart', JSON.stringify(cart.filter(({ id }) => id !== tmpId).map(({ id }) => id)))
    document.dispatchEvent(event)
    doRefreshCart()
  }

  return (
    <React.Fragment>
      <div className="table__container">
        <Table classes={{ root: 'c=text r elevated table' }}>
          <TableHead>
            <TableRow>
              <TableCell>{Strings.de.components.check.typeShort}</TableCell>
              <TableCell>{Strings.de.components.check.name}</TableCell>
              <TableCell></TableCell>
              <TableCell className=" c=text" align="center">
                {Strings.de.components.check.intoCart}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table__body">
            {!!checks.length &&
              checks.map(check => {
                const { id, title, norm, description, valid, pdfLabels } = check

                if (!valid) {
                  return
                }

                const inCart = !!cart.filter(({ id }) => id === check.id).length

                return (
                  <TableRow key={id}>
                    <TableCell>{pdfLabels.title}</TableCell>
                    <TableCell>{title}</TableCell>
                    <TableCell>
                      <More check={check} />
                    </TableCell>
                    <TableCell className=" c=text" align="center">
                      {!inCart && <IconAddToCart className="c=prim-alt pointer" onClick={() => addToCart(id)} />}
                      {inCart && (
                        <IconRemoveFromCart className="c=prim-alt pointer" onClick={() => removeFromCart(id)} />
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            <Pagination
              count={totalRecords}
              rowsPerPage={perPage}
              page={page}
              onChangePage={(event, newPage) => setPage(newPage)}
              onChangeRowsPerPage={event => setPerPage(parseInt(event.target.value))}
            />
          </TableBody>
        </Table>
      </div>
    </React.Fragment>
  )
}

export default CheckSellTable
export { More }
