import React from 'react'
import { createLayoutPlugin } from '@react-page/create-plugin-materialui'
import { imagePlugin } from '@react-page/plugins-image'
import Link from 'next/link'
import Strings from '../strings'

export default ({ imageUpload, categories }) =>
  createLayoutPlugin<{
    category: string
    title: string
    text: string
  }>({
    Renderer: ({ children, state, readOnly }: any) => (
      <div className="p=2">
        <div className="c=text r elevated flex w=full p=4 column align-center pointer">
          {children}
          <h2 className="head-4 mb=2">{state.title}</h2>
          <p>{state.text}</p>
          {readOnly && (
            <Link href={`/checks?category=${encodeURI(state.category)}`}>
              <a className="r-circle c=prim px=3 py=2 mt=2">{Strings.de.components.cms.more}</a>
            </Link>
          )}

          {!readOnly && (
            <button role="button" className="r-circle c=prim px=3 py=2 mt=2">
              {Strings.de.components.cms.more}
            </button>
          )}
        </div>

        <style jsx>{``}</style>
      </div>
    ),
    createInitialChildren: () => {
      return [
        [
          {
            content: { plugin: imagePlugin({ imageUpload }) },
          },
        ],
      ]
    },
    name: 'taenzer-digital/tile',
    text: 'Kachel',
    description: 'Kachelplugin',
    version: '0.0.1',
    schema: {
      required: [],
      properties: {
        category: {
          type: 'string',
          items: {
            type: 'string',
          },
          allowedValues: categories,
          checkboxes: false,
        } as any,
        title: {
          type: 'string',
        },
        text: {
          type: 'string',
        },
      },
    },
  })()
